.shape {
  width: 80px;
  height: 80px;
  background-color: #ff6b6b;
  position: relative;
  border-radius: 10px;
  transition: all 1s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.base {
  width: 85px;
  height: 36px;
  background-color: #e76767;
  border-radius: 5px;
  position: absolute;
  bottom: -8px;
  z-index: 0;
  opacity: 1;
  animation: moveBase 3s ease-in-out infinite;
}

@keyframes moveTopBox {
  0% {
    top: 0px;
    opacity: 1;
  }
  25% {
    top: -10px;
    opacity: 1;
  } /* Moves back to original */
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.topBox {
  width: 34px;
  height: 14px;
  background-color: #bebebe;
  border-radius: 5px;
  position: absolute;
  top: -10px;
  z-index: 0;
  opacity: 1;
  animation: moveTopBox 3s ease-in-out infinite;
}

.box {
  width: 93px;
  height: 49px;
  background-color: #ee9393;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 1;
  animation: boxSet 3s ease-in-out infinite;
}

@keyframes boxSet {
  0% {
    width: 93px;
    height: 49px;
    background-color: #ee9393;
    border-radius: 5px;
  }
  25% {
    width: 93px;
    height: 49px;
    background-color: #ee9393;
    border-radius: 5px;
  }
  50% {
    width: 93px;
    height: 59px;
    background-color: white;
    border-radius: 5px;
  }
  75% {
    width: 59px;
    height: 88px;
    background-color: white;
    border-radius: 2px;
  }
  100% {
    width: 59px;
    height: 88px;
    background-color: white;
    border-radius: 2px;
  }
}

.linesPage {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center; /* Center lines inside box */
  justify-content: flex-start;
  opacity: 0;
  animation: linesMove 3s ease-in-out infinite;
}

.line {
  width: 0px;
  height: 0px;
  background-color: black;
  border-radius: 3px;
  opacity: 0;
  animation: lineResize 3s ease-in-out infinite;
}

@keyframes linesMove {
  0%,
  25% {
    gap: 10px;
    opacity: 0;
  }
  50% {
    gap: 10px;
    opacity: 0;
  }
  75% {
    gap: 10px;
    opacity: 1;
  }
  100% {
    gap: 10px;
    opacity: 1;
  }
}

@keyframes lineResize {
  0%,
  25% {
    width: 0px;
    height: 0px;
    opacity: 0;
  }
  50% {
    width: 0px;
    height: 0px;
    opacity: 0;
  }
  75% {
    width: 22px;
    height: 5px;
    opacity: 1;
  }
  100% {
    width: 32px;
    height: 5px;
    opacity: 1;
  }
}

@keyframes moveBase {
  0% {
    bottom: -8px;
    width: 85px;
    height: 36px;
    background-color: #e76767;
    border-radius: 5px;
    z-index: 0;
    opacity: 1;
  }
  25% {
    bottom: -15px;
    opacity: 1;
  }
  50% {
    bottom: -20px;
    width: 15px;
    height: 36px;
    background-color: #9fa2aa;
    border-radius: 5px;
    z-index: 0;
    opacity: 1;
  }
  75% {
    z-index: 0;
    opacity: 0;
  }
  100% {
    bottom: -15px;
    width: 15px;
    height: 36px;
    background-color: #e76767;
    border-radius: 5px;
    z-index: 0;
    opacity: 0;
  }
}

.badge {
  position: absolute;
  bottom: 20px;
  right: -15px;
  width: 24px;
  height: 24px;
  background-color: #ee9393;
  border-radius: 50%;
  z-index: 4;
  opacity: 1;
  animation: badgeAnimate 3s ease-in-out infinite;
}
.baseCircle {
  position: relative;
  right: -6px;
  top: 5px;
  background-color: #e76767;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.ribbon {
  position: absolute;
  top: 60px;
  right: -8px;
  width: 13px;
  height: 20px;
  background-color: yellow;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 70%, 0 100%, 0 0);
  z-index: 2;
  opacity: 0;
  animation: ribbonAnimate 3s ease-in-out infinite;
}
@keyframes badgeAnimate {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes ribbonAnimate {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.screen {
  top: 5px;
  width: 83px;
  height: 45px;
  background-color: #353535;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 0;
  opacity: 0;
  animation: screenSet 3s ease-in-out infinite;
}
.stand {
  bottom: -20px;
  width: 35px;
  height: 7px;
  position: absolute;
  background-color: #f5f5f5;
  border-radius: 1px;
  opacity: 0;
  animation: setStand 3s ease-in-out infinite;
}

.button1 {
  bottom: 5px;
  right: 5px;
  width: 4px;
  height: 4px;
  position: absolute;
  background-color: #9fa2aa;
  border-radius: 50%;
  opacity: 0;
  z-index: 3;
  animation: setButton 3s ease-in-out infinite;
}
.button2 {
  bottom: 5px;
  right: 13px;
  width: 4px;
  height: 4px;
  position: absolute;
  background-color: #9fa2aa;
  border-radius: 50%;
  opacity: 0;
  z-index: 3;
  animation: setButton 3s ease-in-out infinite;
}

@keyframes setStand {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes screenSet {
  0% {
    z-index: 0;
    opacity: 0;
  }
  25% {
    z-index: 0;
    opacity: 0;
  }
  50% {
    z-index: 2;
    opacity: 1;
  }
  75% {
    z-index: 0;
    opacity: 0;
  }
  100% {
    z-index: 0;
    opacity: 0;
  }
}

@keyframes setButton {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes scrollUp {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  25% {
    transform: translateY(10px);
    opacity: 0.5;
  }
  50% {
    transform: translateY(0px);
    opacity: 1;
  }
  75% {
    transform: translateY(-10px);
    opacity: 0.5;
  }
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}

@keyframes colorChange {
  0% {
    background-color: #e76767;
  } /* Initial Color */
  33% {
    background-color: #69c1a0;
  } /* Mid Color */
  66% {
    background-color: #6a98f0;
  } /* Last Color */
  100% {
    background-color: #e76767;
  } /* Loop Back */
}

@keyframes scrollUp {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  25% {
    transform: translateY(10px);
    opacity: 0.5;
  }
  50% {
    transform: translateY(0px);
    opacity: 1;
  }
  75% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}

/* Color changing animation (Optional) */
@keyframes colorChange {
  0% {
    background-color: #e76767;
  }
  20% {
    background-color: #69c1a0;
  }
  40% {
    background-color: #6a98f0;
  }
  60% {
    background-color: #edad4d;
  }
  80% {
    background-color: #e76767;
  }
  100% {
    background-color: #69c1a0;
  }
}

/* Apply animation to plates */
.screen .plate {
  width: 27px;
  height: 8px;
  border-radius: 1px;
  z-index: 4;
  position: relative;
  animation: scrollUp 2s ease-in-out infinite, colorChange 2s infinite;
}

/* Apply animation to separators */
.screen .separator {
  width: 35px;
  height: 1px;
  background-color: #d9d9d9;
  margin: 5px 0;
  position: relative;
  animation: scrollUp 2s ease-in-out infinite;
}

/* Ensure correct order with delays */
.screen .plate:nth-of-type(1),
.screen .separator:nth-of-type(2) {
  animation-delay: 0s;
}

.screen .plate:nth-of-type(2),
.screen .separator:nth-of-type(4) {
  animation-delay: 0.3s;
}

.screen .plate:nth-of-type(3),
.screen .separator:nth-of-type(6) {
  animation-delay: 0.6s;
}

.plus {
  font-size: 24px;
  font-weight: bold;
  color: white;
  text-align: center;
  opacity: 1;
  animation: increaseFontSize 3s ease-in-out infinite;
}

.circle {
  width: 11px;
  height: 8px;
  background-color: #e9f15b;
  border-radius: 40%;
  position: absolute;
  z-index: 2;
  bottom: 2px;
  opacity: 1;
  animation: expandCircle 3s ease-in-out infinite;
}

/* Keyframes for animations */
@keyframes increaseFontSize {
  0% {
    font-size: 24px;
    opacity: 1;
  }
  25% {
    font-size: 34px;
    opacity: 1;
  }
  50%,
  75%,
  100% {
    font-size: 0px;
    opacity: 0;
  }
}

@keyframes expandCircle {
  0% {
    width: 11px;
    height: 8px;
    bottom: 2px;
    opacity: 1;
  }
  25% {
    width: 20px;
    height: 14px;
    bottom: -5px;
    opacity: 1;
  }
  50%,
  75%,
  100% {
    opacity: 0;
  }
}
